import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import './ChatHistory.css';

const formatData = (inputData) => {
    // Remove the <!-- --> pattern
    const withoutComments = inputData.replace(/<!--[\s\S]*?-->/g, '');

    // Replace numbers with a single dot (.) with numbers having \. instead
    const correctedNumbers = withoutComments.replace(/(\d+)\.(\s)/g, "$1\\. ");

    // Replace **<a href="link">Name</a>** with [Name](link)
    const formattedText = correctedNumbers.replace(/\*\*<a href="([^"]+)">([^<]+)<\/a>\*\*/g, "[$2]($1)");

    return formattedText;
}

const ChatHistory = ({ chatHistory }) => {
    return (
        <div>
            {chatHistory ? chatHistory.map((chat,index) => (
                <div className="message-wrapper" key={index}>
                    <div
                        className={
                            'message ' +
                            (chat.role == 'assistant' ? 'assistant' : 'user')
                        }
                    >
                        <Markdown>{formatData(chat.content)}</Markdown>
                    </div>
                </div>
            )) :
                <div className="message-wrapper flex justify-center p-3">
                    <div className={'assistant'}>
                        No message history to show
                    </div>
                </div>
            }
        </div>
    );
};

export default ChatHistory;

import React from 'react';
import noMessageCard from '../../assets/noMessageCard.png'
import GNCCopilot from '../../assets/logo512.png'
import techolution from '../../assets/techolution.svg'
import './NoMessageCard.css'
import moment from "moment";
import { IonSegment, IonSegmentButton, IonLabel, IonButton } from '@ionic/react';
import { USER_STATUS } from '../../dao/user';

const NoMessageCard = ({...props}) => {
    return (
        <div className="nomessage-container flex flex-col justify-center items-center">
            <div className=" relative w-[22.375rem] h-[28rem] bg-white rounded-[0.625rem] shadow-[0_2px_10px_4px_rgba(0,0,0,0.07)] flex justify-center items-center flex-col gap-1">
                {/* <img
                    src={noMessageCard}
                    alt="No Message Image"
                    className="w-[10.52844rem] h-[10.26763rem]"
                /> */}
                <img
                    src={GNCCopilot}
                    alt="No Message Image"
                    className="w-[8rem] h-[8rem]"
                />
                <div className="px-4 mb-10 mx-5 text-[#333] text-center font-[400] text-[1.25rem]">
                    There are no messages that need to be reviewed
                </div>
                <div className='mb-10'>
                    <IonSegment value={props.userStatus} onIonChange={e => {
                        props.setUserStatus(e.detail.value);
                    }}>
                        <IonSegmentButton value={USER_STATUS.OFFLINE}>
                            <IonLabel>Offline</IonLabel>
                        </IonSegmentButton>
                        {/* <IonSegmentButton value={USER_STATUS.PAUSED}>
                            <IonLabel>Pause</IonLabel>
                        </IonSegmentButton> */}
                        <IonSegmentButton value={USER_STATUS.ACTIVE}>
                            <IonLabel>Online</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    {
                        props.userStatus === USER_STATUS.ACTIVE ? <span className='text-[0.6rem]'>You are online, you will start seeing messages soon.</span> : ''
                    }
                    {
                        props.userStatus === USER_STATUS.OFFLINE ? <span className='text-[0.6rem]'>You are offline, please switch online to receive messages.</span> : ''
                    }
                    {
                        props.userStatus === USER_STATUS.PAUSED ? <span className='text-[0.6rem]'>Paused. Please turn online once you are back.</span> : ''
                    }
                    
                </div>
                <div className='absolute bottom-5 text-center w-[5rem]'>
                    <span className='text-[0.6rem]'>Powered by</span>
                    <img
                        className='inline'
                        src={techolution}
                        alt="techolution"
                    />
                </div>
            </div>
            {/* <div className="fixed" style={{bottom: 20}}>
                <IonButton size="small" color="medium" onClick={props.showTestMessage}>Show me a test message</IonButton>
            </div> */}
        </div>
    );
};

export default NoMessageCard;

import React from 'react';
import Markdown from 'react-markdown'
import markdownToHTML from '../../helpers/markdowntoHtml'

function boldAndRemoveAsterisksAndCaret(htmlString) {
    // Create regular expressions to match text between * and . and ** and ^.
    const singleAsteriskRegex = /\*(.*?)\./g;
    const doubleAsteriskRegex = /\*\*(.*?)\./g;
    const caretRegex = /\^/g;

    // Replace the matched text with the same text wrapped in <strong> tags and remove asterisks and caret
    let updatedHTML = htmlString
        .replace(singleAsteriskRegex, '<strong>$1.</strong>')
        .replace(doubleAsteriskRegex, '<strong>$1.</strong>');

    // Remove remaining asterisks, double asterisks, and caret
    updatedHTML = updatedHTML.replace(/\*|\*\*|\^/g, '');

    return updatedHTML;
}

const ReferencesCard = ({referenceDocs}) => {
    
    return (
        <>
            <div className="max-h-[46.42rem] w-[100%] flex flex-col overflow-y-scroll gap-[1.06rem] lg:max-h-0 lg:min-h-[85vh]">
                
                {referenceDocs?.map((reference,index)=>{
                // /Name: (.*?)  SKU: (\d+)  URL: (https:\/\/[^\s]+)  Image URL: (https:\/\/[^\s]+)/
                // For extracting details: /Name: (.*?)\s+SKU: (\d+)\s+URL: (https?:\/\/[^\s]+)\s+Image URL: (https?:\/\/[^\s]+)\s+Details:\s+([\s\S]*)/
                // const pattern = /Name: (.*?)\s+SKU: (\d+)\s+URL: (https:\/\/[^\s]+)\s+Image URL: (https:\/\/[^\s]+)/;
                // const pattern = /Name: (.*?)\s+SKU: (\d+)\s+URL: (https?:\/\/[^\s]+)\s+Image URL: (?=https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif)(?:\?[^\s]+)?)(https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif)(?:\?[^\s]+)?)/;

                // const match = reference.match(pattern);
                // const [,name, sku, url ] = match || []

                // Regular expressions to extract Name, SKU, and URL
                const nameRegex = /Name:\s*(.*?)\s*SKU:/;
                const skuRegex = /SKU:\s*(.*?)\s*URL:/;
                const urlRegex = /URL:\s*(.*?)\s*Image URL:/;

                // Extracting Name, SKU, and URL
                const nameMatch = reference.match(nameRegex);
                const skuMatch = reference.match(skuRegex);
                const urlMatch = reference.match(urlRegex);

                // Getting the extracted values
                const name = nameMatch ? nameMatch[1] : 'Name not found';
                const sku = skuMatch ? skuMatch[1] : 'SKU not found';
                const url = urlMatch ? urlMatch[1] : 'URL not found';

                const detailsIndex = reference.indexOf('Details:');
                const detailsSection = reference.substring(detailsIndex + 'Details:'.length).trim();
                
                let imageURLSection = ''
                const imageURLIndex = reference.indexOf('Image URL:');
                const detailsIndex2 = reference.indexOf('Details:', imageURLIndex);

                if (imageURLIndex !== -1 && detailsIndex2 !== -1) {
                    // console.log(imageURLIndex, detailsIndex2)
                    imageURLSection = reference.substring(imageURLIndex + 'Image URL:'.length, detailsIndex2).trim();
                    // console.log(imageURLSection);
                }

                // console.log(reference)
                // console.log(imageUrl2)
                // console.log(detailsSection)
                // console.log(markdownToHTML(detailsSection));
                
                return (<div className="flex w-[21.5rem] min-h-fit flex-col justify-center items-start bg-[#F5F5F5] rounded-[0.5rem]" key={index}>
                    
                    <div className='w-[100%] flex justify-between'>
                        <div className="text-[#7F7F81] text-[0.65863rem] font-[500] p-[0.69rem]">
                            Reference {index+1}
                        </div>
                        <div className="text-[#7F7F81] text-[0.65863rem] font-[500] p-[0.69rem]">
                            SKU {sku}
                        </div>
                    </div>
                    {/* <div className="text-[#000000] text-[0.875rem] text-[400] leading-4 text-left p-[0.56rem]">
                        SNAP® Supplements Heart Health Blood Pressure Support |
                        GNC
                    </div>
                    <div className="text-[#000000] text-[0.75rem] font-[400] leading-4 text-left p-[0.56rem]">
                        30.99 USD
                    </div> */} 
                    <div className="min-w-[100%] flex flex-col gap-8 justify-center min-h-fit overflow-scroll text-[#000000] text-[0.875rem] leading-4 text-left p-[0.69rem] lg:overflow-auto">
                       
                       <div className='min-w-[100%] bg-white h-[5.31rem] flex flex-row'>
                            {imageURLSection ? <><img src={imageURLSection} alt='Product Image missing'></img><br/></> : <div>hi</div>}
                            <div className='w-full h-full flex flex-col flex-wrap'> 
                                    <div className='font-bold leading-4 min-h-[3rem] p-2'>{name}</div> <br/>
                                    <button className='w-[95%] h-5 justify-end text-[0.75rem] flex flex-row gap-1 flex-wrap'>
                                            <a href={url} target="_blank" rel="noopener noreferrer" className='text-[#395A8F] underline'>Open product page</a>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                            <g clipPath="url(#clip0_3070_6838)">
                                                <path d="M11 0.5V9.4375H8.9375V11.5H0V2.5625H2.0625V0.5H11ZM10.3125 8.75V1.1875H2.75V2.5625H4.125V3.25H0.6875V10.8125H8.25V7.375H8.9375V8.75H10.3125ZM5.0542 6.9292L4.5708 6.4458L7.76123 3.25H5.5V2.5625H8.9375V6H8.25V3.73877L5.0542 6.9292Z" fill="#395A8F"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3070_6838">
                                                <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                    </button>
                            </div>
                       </div>

                       <div className='font-light whitespace-pre-wrap'>
                            <div dangerouslySetInnerHTML={{ __html: boldAndRemoveAsterisksAndCaret(markdownToHTML(detailsSection)) }}></div>
                        {/* <div>{detailsSection}</div> */}
                        </div>

                       
                        
                        {/* <div className=' font-bold leading-none'>{name}</div> <br/>
                        <button className='pb-3 text-[0.75rem] flex flex-row gap-1'><a href={url} target="_blank" rel="noopener noreferrer">Open product page</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                        <g clip-path="url(#clip0_3070_6838)">
                            <path d="M11 0.5V9.4375H8.9375V11.5H0V2.5625H2.0625V0.5H11ZM10.3125 8.75V1.1875H2.75V2.5625H4.125V3.25H0.6875V10.8125H8.25V7.375H8.9375V8.75H10.3125ZM5.0542 6.9292L4.5708 6.4458L7.76123 3.25H5.5V2.5625H8.9375V6H8.25V3.73877L5.0542 6.9292Z" fill="#395A8F"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3070_6838">
                            <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                        </defs>
                        </svg></button>
                        <img src={imageUrl} alt='Product Image missing'></img><br/>
                        <div className='font-light'>
                            <div dangerouslySetInnerHTML={{ __html: markdownToHTML(detailsSection) }}></div>
                        </div> */}
                        {/* <div>ImageURL: <a href={imageUrl} target="_blank" rel="noopener noreferrer">{imageUrl   }</a></div><br/> */}
                    </div>
                    
                </div>)})}
            </div>
        </>
    );
};

export default ReferencesCard;

// react/productpreview.js

import React from 'react';
import { IonAvatar, IonButton, IonImg, IonLabel, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import { trash } from 'ionicons/icons';
import { swapHorizontalOutline } from 'ionicons/icons';
import './productpreview.css'
export default class ProductPreview extends React.Component {
    render() {
        const style = {
            '--product-image': `url(${this.props.image})`,
            backgroundSize: 'auto 80%'
        };

        return this.props.mode === 'list' ? (
            <>
                <IonAvatar slot="start">
                    <IonImg src={this.props.image} />
                </IonAvatar>
                <IonLabel>
                    <h2>{this.props.name}</h2>
                    <p>{this.props.price}</p>
                </IonLabel>
                <IonButton
                    onClick={() => this.props.onClick(this.props.id)}
                    slot="end"
                    size="small"
                    color="danger">
                    <IonIcon className='h-[20px] w-[20px]' slot="icon-only" icon={trash}></IonIcon>
                </IonButton>
            </>
        ) : this.props.mode === 'toplist' ? (<>
            <IonAvatar slot="start">
                <IonImg src={this.props.image} />
            </IonAvatar>
            <IonLabel>
                <h2>{this.props.name}</h2>
                <p>{this.props.price}</p>
            </IonLabel>
            <IonButton
                onClick={() => this.props.onClick(this.props.id)}
                slot="end"
                size="small"
                fill="clear">
                <IonIcon slot="icon-only" icon={this.props.action === 'add' ? add : swapHorizontalOutline}></IonIcon> {this.props.action === 'add' ? 'Add' : 'Replace'}
            </IonButton>
        </>) : this.props.mode === 'legacy' ? (<div
            className="product-preview"
            style={style}
        >
            <button
                className="product-preview__add"
                onClick={() => this.props.onClick(this.props.id)}
                title="Add to the offer"
            >
                <span>+</span>
            </button>
            <IonImg src={this.props.image} />
            <span className="product-preview__name">{this.props.name}</span>
            <span className="product-preview__price">from {this.props.price}</span>
        </div>) : (
            <>
                <span class="link">{this.props.name}</span>
                <div class="product">
                    <p class="image">
                        <img src={this.props.image} alt="Product Image"/>
                    </p>
                    <p class="title">{this.props.name}</p>
                    <p class="price">{this.props.price} {this.props.currency}</p>
                    <p>
                        <a class="action" data-role="preview" target="_blank" href={this.props.id}>Show Details</a>
                    </p>
                </div>
            </>
        )
    }
}

// App.jsx / App.tsx

import {
    useLayoutEffect,
    useState,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import { createRoot } from 'react-dom/client';
import './App.css';
import { products } from './products/products';
import initFirebaseMessaging from '../src/services/Firebase/Firebase'
import { onValue, ref } from "@firebase/database";

import getProducts from './services/Firebase/Search';

/**
 * Firebase push notofiations
 */
// import useFirebaseMessaging from '@useweb/use-firebase-messaging';

/**
 * GSAP
 */
import gsap from 'gsap';
/**
 * CKEditor
 */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
// import { BalloonEditor } from '@ckeditor/ckeditor5-editor-balloon';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { List } from '@ckeditor/ckeditor5-list';
import { DocumentList } from '@ckeditor/ckeditor5-list';
import AdjacentListsSupport from '@ckeditor/ckeditor5-list/src/documentlist/adjacentlistssupport.js';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';
import AddProductToolbar from './ckeditor/addproduct';
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
// custom plugins
import ProductPreviewEditing from './ckeditor/productpreviewediting';

/***
 * Product Compoenents
 */
// React components to render the list of products and the product preview.
import ProductList from './components/ProductList/productlist';
import ProductPreview from './components/ProductPreview/productpreview';

/**
 * Ionic
 */
import {
    IonApp,
    setupIonicReact,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFooter,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonToast,
    IonModal,
    IonButton,
    IonButtons,
    IonSearchbar,
    IonInput,
    IonLabel,
    IonListHeader,
    IonCol,
    IonGrid,
    IonRow,
    IonAlert,
    IonLoading,
    useIonToast
} from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/**
 * Custom
 */
import TimeBombButton from './components/TimeBombButton/TimeBombButton/TimeBombButton';
import convertAnchorToProductInfo from './helpers/convertAnchorToProductInfo';
import convertProductInfoToAnchor from './helpers/convertProductInfoToAnchor';
import getProductInfoFromLink from './helpers/getProductsFromLinks';
import getAllProductsAsJsonFromLinks from './helpers/getAllProductsAsJsonFromLinks';
import SelectedProductList from './components/SelectedProductList/selectedproductlist';
import removeProductById from './helpers/removeProductById';
import replaceProductById from './helpers/replaceProductById';

import React from 'react';

import ChatHistory from './components/ChatHistory/ChatHistory';
import ScoreCard from './components/ScoreCard/ScoreCard';
import ReferencesCard from './components/ReferencesCard/ReferencesCard';
import EditButtonsCard from './components/EditButtonsCard/EditButtonsCard';
import NoMessageCard from './components/NoMessageCard/NoMessageCard';
import LoginCard from './components/LoginCard/LoginCard';
import SuccessCard from './components/SuccessCard/SuccessCard';
import { getQueueSubscriptionForCopilotID, markQuestionAsTimedout, markQuestionAsSkipped, updateQuestion, addSampleQuestionToTest } from './dao/question';
import getCopilotId, { copilotID } from './dao/copilot';
import { pause, offline, online, USER_STATUS } from './dao/user';
import removePTags from './helpers/removePTags';
import replaceBrWithLinebreak from './helpers/replaceBrWithLineBreak';
import htmlToMarkdown from './helpers/htmlToMarkdown';
import ResponseNeeded from '../src/components/ResponseNeeded/ResponseNeeded'

const urlParams = new URLSearchParams(window.location.search);
const orgId = urlParams.get('orgId');
const projectId = urlParams.get('projectId');
const copilotIdParam = urlParams.get('copilotId');

setupIonicReact();
const firebase = initFirebaseMessaging();

const App = () => {
    const el = useRef();
    const tl = useRef();
    const modal = useRef(null);
    const [selectedProductForEditing, setSelectedProductForEditing] = useState('');
    const [productUrlToAdd, setProductUrlToAdd] = useState('');
    const [showDuplicateProductAlert, setShowDuplicateProductAlert] = useState(false);
    const [showProductSearchPanel, setShowProductSearchPanel] = useState(false);
    const [editorInstance, setEditorInstance] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const defaultTimeLimit = 30; // seconds
    const [timer, setTimer] = useState(0);
    const [timeLimit, setTimeLimit] = useState(defaultTimeLimit);

    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [userStatus, setUserStatus] = useState(USER_STATUS.ACTIVE);
    const [loggedIn, setLoggedIn] = useState(false);

    const [currentQuestionObject, setCurrentQuestionObject] = useState(null);
    const [products, setProducts] = useState([]);
    const [present] = useIonToast();

    const [invalidURL,setInvalidURL] = useState(false);

    /**
     * presentToast message
     * @param {*} position  'top' | 'middle' | 'bottom'
     * @todo add color param
     */
    const presentToast = (position, message, duration = 1500, color="light") => {
      present({
        message: message,
        duration: duration,
        position: position,
        color: color
      });
    };

    // /**
    //  * Code to change this based on a login
    //  */
    // useEffect(() => {
    //     let unsubscribe;
    //     if(loggedIn) {
    //         getCopilotId(copilotIdParam).then(copilotId => {
    //             unsubscribe =
    //                 getQueueSubscriptionForCopilotID(orgId, projectId, copilotId, setCurrentQuestionObject);
    //         })
    //         return unsubscribe? () =>  unsubscribe(): null;
    //     } else {
    //         if(unsubscribe) unsubscribe()
    //     }
    // }, [loggedIn])

    /**
     * Subscribe to the questions for the copilot
     */
    useEffect(() => {
        let unsubscribe;
        getCopilotId(copilotIdParam).then(copilotId => {
            unsubscribe =
                getQueueSubscriptionForCopilotID(orgId, projectId, copilotId, setCurrentQuestionObject, setTimeLimit);
        })
        return unsubscribe? () =>  unsubscribe(): null;
    }, [])



    /**
     * Just logging currentQuestionObject
     */
    useEffect(() => {
        // console.log(currentQuestionObject, "currentQuestion");
        // setTimer(0);
    }, [currentQuestionObject]);

    /**
     * To be called when CKEditor is initialized and ready to take input
     * @param {*} editor 
     */
    const editorReady = (editor) => {
        setEditorInstance(editor);
    }

    /**
     * Update the model when the editor value is changing
     * @param {*} event 
     * @param {*} editor 
     * @returns 
     */
    const updateCurrentQuestionObjectSelectedAnswer = (event, editor) => {
        setCurrentQuestionObject({ ...currentQuestionObject, selected_answer: editor.getData() })
    }

    /**
     * Save the answer to DB and mark as Approved
     */
    const updateCorrectedAnswerInDBAndApprove = async () => {
        const answerConvertedToAnchor = convertProductInfoToAnchor(currentQuestionObject.selected_answer, selectedProducts);
        // const markdown = down(answerConvertedToAnchor);
        // const answerWithPTagsRemoved = removePTags(answerConvertedToAnchor); //@todo regex is  now
        // const enswerWithBrReplaced = replaceBrWithLinebreak(answerWithPTagsRemoved);
        // console.log("Answer converted update section",answerConvertedToAnchor.replace(/-\s*\*\*/g, '**'))
        // Remove duplicate numbers, keeping only the first occurrence
        let cleanedText = answerConvertedToAnchor.replace(/(\d+)\.\s*\*\*\1\\\. /g, '$1\\. ');
        // Replace bold formatting with plain numbering
        cleanedText = cleanedText.replace(/\*\*(\d+\\\.)/g, '$1');
        let newAnswer = cleanedText.replace(/-\s*\*\*/g, '**').replace(/\*\*/g, '');
        try {
            const updateResult = await updateQuestion(
                copilotID,
                currentQuestionObject.id,
                newAnswer)
            // console.log("updateResult", updateResult);
        } catch(e) {
            presentToast('top', 'Error updating answer', 1000);
        }

        presentToast('top', 'Updated successfully', 1000);
        setIsEditing(false);

        // @todo Catch error scenario
        // @todo This will not guaratee that firebase susbsription will get a new item?
    };

    /**
     * Mark this question as skipped and do not edit the answer
     */
    const skipQuestionInDB = () => {
        markQuestionAsSkipped(copilotID, currentQuestionObject.id);
    }

    /**
     * Side effect to get products for a message
     * @todo refactor this
     */
    useEffect(() => {
        if (currentQuestionObject) {
            // console.log('setting loading true')
            // setLoading(true);
            getAllProductsAsJsonFromLinks(currentQuestionObject.selected_answer).then((selectedProducts) => {
                setSelectedProducts(selectedProducts);
                if (
                    selectedProductForEditing !== '' &&
                    !selectedProducts.some(
                        (product) => product.id === selectedProductForEditing
                    )
                ) {
                    setSelectedProductForEditing('');
                }
                // console.log('setting loading false')
                // setLoading(false);
            });
        }
    }, [currentQuestionObject]);

    /**
     * Check the product URL if its from gnc or not
     * @param {*} link - using product Href ID as link 
     */
    const checkURL = (link) => {
        let url = document.createElement('a');
        url.href = link;
        return url.hostname === "www.gnc.com";
    }

    /**
     * Add / Replace Product
     * @param {*} id - using product Href as ID now
     */
    const addProduct = (id) => {
        setProductUrlToAdd('')
        if(checkURL(id))
        {
            if (!productExistInSelection(id)) {
                editorInstance.execute('insertProduct', id);
                editorInstance.editing.view.focus();
                setSelectedProductForEditing(id);
            } else {
                setShowDuplicateProductAlert(true);
            }
        }
        else
        {
            setInvalidURL(true);
        }
        
    };

    const hasSelectedProduct = () => selectedProductForEditing !== '';

    /**
     * Replace a product
     * @param {*} id 
     * @param {*} productToReplace 
     */
    const replaceProduct = (id, productToReplace) => {
        setProductUrlToAdd('')
        if(checkURL(id))
        {
            if (!productExistInSelection(id)) {
                setCurrentQuestionObject({
                    ...currentQuestionObject,
                    selected_answer: replaceProductById(currentQuestionObject.selected_answer, productToReplace, id)
                });
                setSelectedProductForEditing(id);
            } else {
                setShowDuplicateProductAlert(true);
            }
        }
        else
        {
            setInvalidURL(true);
        }
        
    }

    /**
     * Remove a product
     * @param {*} id 
     */
    const removeProduct = (id) => {
        setCurrentQuestionObject({
            ...currentQuestionObject,
            selected_answer: removeProductById(currentQuestionObject.selected_answer, id)
        });
    };

    /**
     * Persist changes to userStatus
     */
    useEffect(() => {
        switch(userStatus) {
            case USER_STATUS.OFFLINE: offline(copilotIdParam); break;
            case USER_STATUS.ACTIVE: online(copilotIdParam); break;
            case USER_STATUS.PAUSED: pause(copilotIdParam); break;
            default: console.error("Unknown userstatus", userStatus);
        }
    }, [userStatus])

    /**
     * Create a test message in the DB for the user.
     */
    const showTestMessage = () => {
        addSampleQuestionToTest(copilotIdParam);
    }

    /**
     * Load products from API
     * @param {*} query 
     */
    const loadProducts = (query) => {
        setProducts(null);
        getProducts(query).then(products => {
            // console.log(products, "result of search");

            // Filter out products with similar IDs
            const filteredProducts = products.filter((product, index, self) => 
            index === self.findIndex(p => ( p.id === product.id)));

            setProducts(filteredProducts);
        })
    }
    /**
     * 
     * OLD CODE -------------------------------------------------------------------------- OLD CODE
     */


    useEffect(() => {
        let interval;
        if (currentQuestionObject) {
            // console.log("starting timer")
            interval = setInterval(() => {
                // console.error("interval called", isEditing, loading, timer, timeLimit)
                if (!isEditing) {
                    if (timer < timeLimit) {
                        if (!loading) {
                            setTimer(timer + 1);
                        }
                    } else {
                        markQuestionAsTimedout(copilotID, currentQuestionObject.id);
                        presentToast('top', "Timed out! try the next one.", 2000, 'danger')
                        setTimer(0)
                    }
                } else {
                    clearInterval(interval);
                }
            }, 1000);
        }
        // console.log(timer);

        return () => clearInterval(interval);
    }, [
        timer,
        currentQuestionObject,
        loading,
        timeLimit
    ]);

    const handleEdit = () => {
        setTimer(0);
        // console.log('Edit mode started');
        setIsEditing(true);
    };

    const animateFirstQuestion = () => {
        const ctx = gsap.context(() => {
            // const obj = { strokeDashoffset: 2900 }
            gsap.set('.answer, .messages', {
                autoAlpha: 0,
                display: 'none',
            });
            tl.current = gsap
                .timeline()
                .to('.question', {
                    fontSize: 24,
                    duration: 0.3,
                    delay: 2,
                })
                .to('.answer', {
                    autoAlpha: 1,
                    delay: 0,
                    duration: 1,
                    onStart: () => {
                        gsap.set('.answer', { display: 'block' });
                    },
                })
                .to('.messages', {
                    autoAlpha: 1,
                    delay: 3,
                    duration: 1,
                    onStart: () => {
                        gsap.set('.messages', {
                            display: 'block',
                            visibility: 'hidden',
                        });
                        // scrollToBottom();
                    },
                    onComplete: () => {
                        // scrollToBottom();
                    },
                });
        }, el);
        // console.log(ctx);
    };

    const editorConfiguration = {
        plugins: [
            // A set of editor features to be enabled and made available to the user.
            Essentials,
            // Heading,
            Bold,
            Italic,
            Underline,
            Link,
            // Table,
            // TableToolbar,
            AddProductToolbar,
            Paragraph,
            // Markdown,
            // Your custom plugin implementing the widget is loaded here.
            ProductPreviewEditing,
            // List,
            // DocumentList,
            // AdjacentListsSupport,
            // Autoformat
        ],
        list: {
            multiBlock: false // Turn off the multi block support (enabled by default).
        },
        toolbar: [
            'link',
            '|',
            'undo',
            'redo',
            '|',
            'addproduct',
        ],
        label: {
            undo: 'Undo',
            redo: 'Redo'
        },        
        // The configuration of the Products plugin. It specifies a function that will allow
        // the editor to render a React <ProductPreview> component inside a product widget.
        products: {
            productRenderer: async (id, domElement) => {
                // setLoading(true);
                // console.log("setting loading from product component true")
                const product = await getProductInfoFromLink(id);
                // console.log(product, 'PRODUCT', id);
                const root = createRoot(domElement);
                root.render(<ProductPreview id={id} {...product} />);
                // console.log("setting loading from product component false")
                // setLoading(false);

            },
            openProductDialog: () => {
                setShowProductSearchPanel(true);
            },
        },
    };



    /**
     * Card Animation
     */
    useLayoutEffect(animateFirstQuestion, []);

    useEffect(() => {
        if (!editorInstance) return;
        const editor = editorInstance; // Add Click Handler
        const view = editor.editing.view;
        const viewDocument = view.document;

        view.addObserver(ClickObserver);

        editor.listenTo(viewDocument, 'click', (evt, data) => {
            // console.log(
            //     'Clicked on something',
            //     data.target,
            //     editorInstance.model
            // );

            if (!isEditing) {
                setIsEditing(true);
            }

            if (data.target._classes.has('product__react-wrapper')) {
                const selectedProduct = editor.editing.mapper.toModelElement(
                    data.target.parent
                );
                // console.clear();
                // console.log(
                //     'PRODUCT Selected',
                //     selectedProduct,
                //     selectedProduct._attrs.get('id')
                // );
                setSelectedProductForEditing(selectedProduct._attrs.get('id'));
                // document.activeElement.blur();
                setShowProductSearchPanel(true);
            } else {
                setSelectedProductForEditing('');
                setShowProductSearchPanel(false);
            }
        });
    }, [editorInstance]);

    /**
     * Checks if the product is there in the current list
     * @param {*} id
     * @returns
     */
    const productExistInSelection = (id) =>
        selectedProducts.map((product) => product.id).includes(id);
    /**
     * Event handlers
     */



    const cardHeaderRef = useRef(null);

    useEffect(() => {
        // Check if the ref exists and scrollIntoView
        // console.log("Scroll into view");
        if (cardHeaderRef.current) {
            // console.log("Scroll into view consdition");
            cardHeaderRef.current.scrollIntoView({
                behavior: 'smooth', // You can change this to 'auto' for instant scrolling
                block: 'start',
            });
        }
    }, [editorInstance,currentQuestionObject]);

    //Swipe Logic
    const [startX, setStartX] = useState(null);
    const [showSwipeContent, setShowSwipeContent] = useState(false);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const deltaX = e.touches[0].clientX - startX;

            if (deltaX < -100) {
                setShowSwipeContent(true);
            } else if (deltaX > 0) {
                setShowSwipeContent(false);
            }
        }
    };

    const handleTouchEnd = () => {
        setStartX(null);
    };

    const handleContentTouchMove = (e) => {
        e.stopPropagation(); // Prevent the touch move on the content from affecting the trigger

        if (startX !== null) {
            const deltaX = e.touches[0].clientX - startX;

            if (deltaX > 100) {
                setShowSwipeContent(false);
            }
        }
    };

    const handleSwipeClick = () => {
        setShowSwipeContent(true);
    }

    const handleSwipeClose = () => {
        setShowSwipeContent(false);
    }

    return (
        <>
            <IonApp mode="ios">
            {orgId && projectId && copilotIdParam ? 
                currentQuestionObject ? (
                    <IonPage
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        className="app"
                    >
                        <IonContent
                            ref={el}
                            style={{
                                '--background':
                                    timer < timeLimit * 1/3
                                        ? '#f9f9f9'
                                        : timer < timeLimit * 2/3
                                            ? '#ffb80045'
                                            : '#e60f2d2e'
                            }}
                        >
                            <div
                                style={{
                                    boxShadow: '10px 10px 10px #eee',
                                    padding: '5px',
                                }}
                                className="fixed z-30 w-full flex flex-row justify-between bg-white"
                            >
                                <ScoreCard score={currentQuestionObject.confidenceScore} threshold={currentQuestionObject.confidenceScoreThreshold}></ScoreCard>
                                <div className="inline-flex py-[0.25rem] items-center gap-[0.3125rem] text-[0.75rem] text-center text-[300]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M1.25 7.5L5.625 3.84375V11.1563L1.25 7.5Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M4.6875 6.25H13.125V8.75H4.6875V6.25Z"
                                            fill="black"
                                        />
                                    </svg>

                                    <div
                                        className={`swipe-content ${showSwipeContent ? 'visible' : ''
                                            }`}
                                        style={{
                                            right: showSwipeContent
                                                ? `0`
                                                : '-100%',
                                        }}
                                        onTouchStart={handleTouchStart}
                                        onTouchMove={handleContentTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                    >
                                        <div className="flex flex-col gap-[0.72rem]">
                                            <div className="text-[0.75rem] text-[300] text-left inline-flex py-[0.25rem] gap-[0.3125rem]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 15 15"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1.25 7.5L5.625 3.84375V11.1563L1.25 7.5Z"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M4.6875 6.25H13.125V8.75H4.6875V6.25Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                                <div className="text-[0.75rem]" onClick={handleSwipeClose}>
                                                    Back
                                                </div>
                                            </div>
                                            <ReferencesCard
                                                referenceDocs={currentQuestionObject.referenceDocs}
                                            ></ReferencesCard>
                                        </div>
                                    </div>

                                    <div className="text-center" onClick={handleSwipeClick}>
                                        Swipe/click for references
                                    </div>
                                </div>
                            </div>
                            <IonCard>
                                <ChatHistory chatHistory={currentQuestionObject.chatHistory} />
                            </IonCard>

                            <IonCard className="">
                                <IonCardHeader
                                    id='cardHeader'
                                    ref={cardHeaderRef}
                                    style={{
                                        paddingBottom: 0
                                    }}
                                    class="w-[20rem]"
                                >
                                    {currentQuestionObject && (
                                        <>
                                            <IonCardSubtitle>AI Answer:</IonCardSubtitle>
                                            <IonCardTitle className="question">
                                                {currentQuestionObject.question}
                                            </IonCardTitle>
                                            <IonCardSubtitle>Customer Question</IonCardSubtitle>
                                        </>
                                    )}
                                </IonCardHeader>

                                <IonCardContent style={{ padding: 0 }}>
                                    <div className="answer">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfiguration}
                                            data={currentQuestionObject.selected_answer}
                                            onReady={editorReady}
                                            onFocus={handleEdit}
                                            onChange={updateCurrentQuestionObjectSelectedAnswer}
                                        />
                                    </div>

                                    <IonModal
                                        ref={modal}
                                        isOpen={showProductSearchPanel}
                                        onDidDismiss={() => {
                                            setShowProductSearchPanel(false);
                                            setSelectedProductForEditing('');
                                        }}
                                    >
                                        <IonHeader>
                                            <IonToolbar>
                                                <IonTitle>
                                                    Manage Products
                                                </IonTitle>
                                                <IonButtons slot="start">
                                                    <IonButton
                                                        onClick={() =>
                                                            setShowProductSearchPanel(
                                                                false
                                                            )
                                                        }
                                                    >
                                                        Back
                                                    </IonButton>
                                                </IonButtons>
                                            </IonToolbar>
                                        </IonHeader>
                                        <IonContent
                                            color="light"
                                            className="ion-padding"
                                        >
                                            <IonListHeader>
                                                <IonLabel>
                                                    Selected Products
                                                </IonLabel>
                                            </IonListHeader>
                                            <SelectedProductList
                                                selectedProductForEditing={
                                                    selectedProductForEditing
                                                }
                                                products={selectedProducts}
                                                onClick={removeProduct}
                                                setSelectedProductForEditing={
                                                    setSelectedProductForEditing
                                                }
                                            />

                                            <IonListHeader>
                                                <IonLabel>
                                                    {selectedProductForEditing !==
                                                        ''
                                                        ? 'Replace Product'
                                                        : 'Add Product'}{' '}
                                                    from URL
                                                </IonLabel>
                                            </IonListHeader>

                                            <IonAlert 
                                                isOpen={invalidURL}
                                                header="Invalid URL"
                                                subHeader="Please enter a URL from gnc.com"
                                                buttons={['Close']}
                                                onDidDismiss={()=>setInvalidURL(false)}>
                                            </IonAlert>

                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonInput
                                                        value={productUrlToAdd}
                                                            onIonInput={(e) =>
                                                                setProductUrlToAdd(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            style={{
                                                                '--background':
                                                                    '#ffffff',
                                                                '--border-radius':
                                                                    '10px',
                                                                '--padding-start':
                                                                    '10px',
                                                                '--padding-end':
                                                                    '10px',
                                                            }}
                                                            placeholder="Enter Product URL from GNC.com"
                                                        ></IonInput>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol class="ion-justify-content-end">
                                                        <IonButton
                                                            size="default"
                                                            fill="solid"
                                                            onClick={() => {
                                                                if (
                                                                    productUrlToAdd?.trim()
                                                                        .length
                                                                ) {
                                                                    hasSelectedProduct() ? replaceProduct(productUrlToAdd, selectedProductForEditing) : addProduct(productUrlToAdd);
                                                                } else {
                                                                    alert(
                                                                        'enter a valid url to add'
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {hasSelectedProduct()
                                                                ? 'Replace Product'
                                                                : 'Add Product'}
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>

                                            <IonListHeader>
                                                <IonLabel>
                                                    Search GNC Products
                                                </IonLabel>
                                            </IonListHeader>
                                            <IonSearchbar
                                                onClick={() => { }}
                                                placeholder="Search"
                                                debounce={450}
                                                onIonInput={(e) => loadProducts(e.detail.value)}
                                            ></IonSearchbar>
                                            {/* <div>{products && products.length}</div> */}
                                            <ProductList
                                                key="product-list"
                                                products={products}
                                                action={
                                                    selectedProductForEditing !==
                                                        ''
                                                        ? 'replace'
                                                        : 'add'
                                                }
                                                onClick={(id) => hasSelectedProduct() ? replaceProduct(id, selectedProductForEditing) : addProduct(id)}
                                            />
                                        </IonContent>
                                    </IonModal>
                                </IonCardContent>
                            </IonCard>

                            <IonAlert
                                isOpen={showDuplicateProductAlert}
                                header="Product already exists"
                                subHeader="This product is already in the list."
                                message="Please select a product that is not there in the list"
                                buttons={['Close']}
                                onDidDismiss={() =>
                                    setShowDuplicateProductAlert(false)
                                }
                            ></IonAlert>
                            {/*This was the loading which might cause issue*/}
                            {/* <IonLoading
                                isOpen={loading}
                                message="Fetching data please wait..."
                            /> */}
                        </IonContent>
                        <IonFooter
                            style={{
                                height: 138,
                                paddingTop: 5,
                                boxShadow: 'none',
                                zIndex: '-10',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5
                            }}
                        >
                            <ResponseNeeded createdAt={currentQuestionObject.created_at}/>

                            {!isEditing ? (
                                <div onClick={handleEdit}>
                                    <TimeBombButton
                                        animate={true}
                                        isEditing={false}
                                        loading={loading}
                                        timeLimit={timeLimit}
                                        setIsEditing={setIsEditing}
                                        // updateQuestion={updateCorrectedAnswerInDBAndApprove}
                                    />
                                </div>
                             ) : (
                                <div>
                                    <EditButtonsCard
                                        timer={timer}
                                        updateQuestion={updateCorrectedAnswerInDBAndApprove}
                                        skipQuestion={skipQuestionInDB}
                                    ></EditButtonsCard>
                                </div>
                            )} 
                        </IonFooter>
                    </IonPage>
                ) : (
                    <>
                        <NoMessageCard
                            userStatus={userStatus}
                            setUserStatus={setUserStatus}
                            showTestMessage={showTestMessage}
                        ></NoMessageCard>
                    </>
                    
                    ) : 
                    // orgId && projectId? <LoginCard></LoginCard> :
                    'Invalid URL, Contact Admin'
                }
            <audio id="new-message-alert" src="new-message-alert.mp3" preload="auto"></audio>
            </IonApp>
        </>
    );
};

export default App;

import React from 'react';

const EditButtonsCard = ({ timer, updateQuestion, skipQuestion }) => {
    return (
        <div className=" min-w-[13.5625rem] h-[5.8125rem] flex flex-row gap-[1.55rem] justify-center">
            <div
                className="inline-flex flex-col gap-[0.4375rem] items-center"
                onClick={() => {
                    skipQuestion();
                }}
            >
                <div className="w-[3.82rem] h-[3.82rem]">
                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 9822">
                    <g id="Group 165363">
                    <g id="Group 165365">
                    <g id="Path 14608">
                    <path d="M22 18L37.3678 31.2192L22 44" fill="#767474"/>
                    <path d="M22 18L37.3678 31.2192L22 44" stroke="white" stroke-width="6" stroke-linecap="round"/>
                    </g>
                    <g id="Path 14609">
                    <path d="M29.6841 18L45.0518 31.2192L29.6841 44" fill="#767474"/>
                    <path d="M29.6841 18L45.0518 31.2192L29.6841 44" stroke="white" stroke-width="6" stroke-linecap="round"/>
                    </g>
                    </g>
                    <path id="Path 14937" d="M30.56 3.13427C35.9843 3.13427 41.2868 4.74273 45.7969 7.75632C50.3071 10.7699 53.8223 15.0532 55.8981 20.0646C57.9739 25.076 58.517 30.5905 57.4588 35.9105C56.4006 41.2306 53.7885 46.1174 49.953 49.9529C46.1174 53.7885 41.2306 56.4005 35.9105 57.4588C30.5904 58.517 25.076 57.9739 20.0646 55.8981C15.0532 53.8223 10.7699 50.3071 7.75632 45.7969C4.74274 41.2868 3.13423 35.9843 3.13423 30.56C3.14232 23.2887 6.03443 16.3175 11.176 11.176C16.3176 6.03439 23.2887 3.14235 30.56 3.13427ZM30.56 0C24.5158 0 18.6073 1.79231 13.5818 5.15028C8.55622 8.50826 4.63926 13.2811 2.32625 18.8652C0.013236 24.4493 -0.591947 30.5939 0.587217 36.522C1.76638 42.45 4.67692 47.8953 8.95082 52.1692C13.2247 56.4431 18.67 59.3537 24.598 60.5328C30.5261 61.712 36.6707 61.1068 42.2548 58.7938C47.8389 56.4807 52.6117 52.5638 55.9697 47.5383C59.3277 42.5127 61.12 36.6042 61.12 30.56C61.12 22.455 57.9003 14.6819 52.1692 8.95081C46.4381 3.2197 38.665 0 30.56 0Z" fill="#767474"/>
                    </g>
                    </g>
                    </svg>
                </div>
                <div className="text-[#767474] text-[0.8125rem] font-[700]">
                    Skip
                </div>
            </div>

            <div className="opacity-0 w-[2.3125rem] h-[1.0625rem] mt-[2.06rem] text-[0.875rem] text-center text-[#767474] font-[300] leading-4 tracking-[0.035rem]">
                {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
            </div>
            <div
                className="inline-flex flex-col gap-[0.44rem] items-center"
                onClick={() => {
                    updateQuestion();
                }}
            >
                <div className="w-[3.82rem] h-[3.82rem]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="62"
                        height="62"
                        viewBox="0 0 62 62"
                        fill="none"
                    >
                        <circle
                            cx="30.56"
                            cy="30.56"
                            r="29.56"
                            fill="#F2FFF0"
                            stroke="#76AC5C"
                            stroke-width="2"
                        />
                        <path
                            d="M25.8287 41.2441L15.8906 31.3085L18.1398 29.0592L25.8287 36.7469L42.5756 20L44.8248 22.2492L25.8287 41.2441Z"
                            fill="#82B854"
                        />
                    </svg>
                </div>
                <div className="text-[#82B854] text-[0.8125rem] font-[700]">
                    Send
                </div>
            </div>
        </div>
    );
};

export default EditButtonsCard;
